export default {
    meta: {
        fitshow: "FitShow- Smart Fitness Equipments Solution Provider",
        article: "Article",
        appDownload: "App Download",
        contact: "Contact Us",
        about: "About FitShow",
        fitshowApp: "FitShow APP",
        oemApp: "OEM APP",
        embeddedApp: "Brand Embedded APP",
        qrOta: "Ota Tools",
        logoutApp: "Delete App Account"
    },
    lang: "Language",
    fitshow: "FitShow",
    learnMore:"Learn More",
    home:"Home",
    products:"Products",
    software:"Software",
    apps:"Apps",
    download:"Download",
    about:"About", 
    contact:"Contact",
    smartHardware:"Smart Hardware",
    smartHardwareDetail:"FitShow hardware develops a variety of intelligent modules, including Bluetooth, WiFi, Voice, RF, IR, NFC.",
    appService:"APP Service",
    appServices:"APP Services",
    appServiceDetail:"With FitShow smart hardware, it is perfectly compatible with FitShow APP, Kinomap, Onelap, Zwift and APPs supporting FTMS protocol.",
    appCustom:"APP Custom",
    appCustomDetail:"We provide branding services in the FitShow app as well as custom development of standalone app.",
    smartMadeEasy:"Smart Made Easy",
    smartMadeEasyDetail:"FitShow provides smart and integrated solutions to clients who need connected sports products.",
    servicesProvideForYou:"Services provide for you.",
    servicesProvideForYouDetail:"FitShow's services include smart hardware development, APP Services, <br /> and software custom development services.",
    hardwareDevelopment:"Hardware Development",
    hardwareDevelopmentDetail:"Embedded development, communication protocol development, SDK development, Lisence licensing.",
    softwareDevelopment:"Software Development",
    softwareDevelopmentDetail:"Fitness App Development、OEM App、APP SDK、Cloud Development、Data Analytics、SaaS Services.",
    appServicesDetail1:"FitShow hardware is perfectly compatible with FitShow, Kinomap, Onelap, Zwift.",
    ourProducts:"Our Products",
    bluetoothModule:"Bluetooth Module",
    bluetoothModuleDetail:"Single-mode, dual-mode, low energy",
    smartKnob:"Smart Knob",
    smartKnobDetail:"Smart bluetooth knob controller",
    wirelessController:"Wireless Controller",
    wirelessControllerDetail:"Dual bluetooth wireless controllers",
    sisplaySystem:"Display & System",
    sisplaySystemDetail:"TFT screen, android system developme",
    heartRateMonitor:"Heart Rate Monitor",
    heartRateMonitorDetail:"Heart rate bracelet and chest strap",
    resistanceRegulator:"Resistance Regulator",
    resistanceRegulatorDetail:"Sliding adjust resistance",
    allInOneApp:"All-in-One App",
    allInOneAppDetail:"We offer an all-in-one app: FitShow. It is a comprehensive app of fitness indoor, You can use it for free without any operation or maintenance costs, You only need to develop the hardware and don\'t need to invest additional resources in software R&D. The application can be integrated with a variety of sports scenarios and devices and is available in multiple languages, including Chinese, English, German, French, Italian, Spanish, Russian, Japanese and Korean, making it easier for you to tap into the global sports equipment market.",
    brandService:"Brand Service",
    brandServiceDetail:"You can manage users through the FitShow App, and you can open a shop within the FitShow App, view user details, view progress of each member, 1-1 chat with members, handle customers support requests, dashboard: traffc, workout performance -export/ importexcel sheets, bulk notifcations, etc.",
    oemAPP:"OEM APP",
    oemAPPDetail:"Redesigned brand-specific UI based on all the features of FitShow App. Use the FitShow App SDK and UI Biz Bundle to quickly develop a customized smart fitness app.",
    fitnessAppDevelopment:"Fitness App Development",
    fitnessAppDevelopmentDetail:"Deep custom software development according to brand needs. Including App Development, Cloud Development, SDK Development, MCU Development, Data Analytics.",
    fitshowApp:"FitShow App",
    fitshowAppDetail:"Devices using the FitShow Bluetooth module can use the FitShow APP for free.",
    originalApp:"Basic Version",
    originalAppDetail:"FitShow generic interface and content display, does not support backend management system.",
    brandingServices:"Brand Customization",
    brandingServicesDetail:"Exclusive brand interface and information display, exclusive backend user management system.",
    thirdPartyApp:"Third Party App",
    thirdPartyAppDetail:"The FitShow Bluetooth module is perfectly compatible with Kinomap, Onelap, Zwift and APPs supporting FTMS protocol.",
    smartEnabledDevices:"Smart-Enabled Devices",
    oemPartners:"OEM Partners",
    brandPartners:"Brand Partners",
    regionalDistribution:"Regional Distribution",
    servicesByNumbers:"Services by the numbers",
    powersCustomersWorldwide:"Powers customers worldwide",
    lang:"Lang",
    privacyPolicy:"Privacy Policy",
    termsAndCondition:"Terms And Condition",
    contactUs:"Contact Us",
    downloaded: "GET",
    howToUse: "How to use",
    loading: "loading...",
    fitshowUp: "FITSHOW",
    fitshoUpDesc: "Workout Indoor",
    address: "Room 1302, B04, Software Park, Xiamen City, Fujian Province, China",
    aboutFs: "About FitShow",
    aboutFsDesc: "For over 8 years, we have been committed to fitness equipment smart services to provide customers with fast and low-cost smart solutions.",
    ourVision: "OUR VISION",
    ourVisionDesc: "Global Smart Fitness Equipments Solution Provider",
    ourMission: "OUR MISSION",
    ourMissionDesc: "All-in-one Solution for Customers, Made Smart Easy",
    ourValues: "OUR VALUES",
    ourValuesDesc: "Customer Success, Purity and Dedicated, Win–win Cooperation, Self-driven Growth",
    globalEquipmentProvider: "Global Smart Fitness Equipments Solution Provider",
    globalEquipmentProviderDesc: "FitShow is a Fitness equipments IoT development platform with a unique, all-in-one offering of cloud + connectivity + app that makes it easy and affordable for brands, retailers, and OEMs to make their products smart. FitShow platform has smart-enabled more than five million equipments in hundreds of enterprise, sold to more than 120+ countries and regions worldwide..",
    contactType: "Our Contact Address Here",
    contactNum: "Contact Phone Number",
    contactEmail: 'Our Email Address',
    contactAddr: "Our Location",
    appService: "App Services",
    fitshowDesc: "We offer an all-in-one app: FitShow. You can use it for free without any operation or maintenance costs, You only need to develop the hardware and don't need to invest additional resources in software R&D.",
    aboutApp: "About App",
    supportEquipment: "Equipment",
    supportEquipmentDesc: "Treadmill, Indoor Bike, Rowing Machine and Elliptical Trainer",
    supportLang: "Language",
    supportLangDesc: "English, German, French, Italian, Spanish, Russian, Japanese, Korean and Chinese",
    features: "Features",
    featuresDesc: "Workouts, Plans, Health Tracker, Data Stats, Meals and so on",
    price: "Pricing",
    priceDesc: "You can use it for free without any operation or maintenance costs",
    featuresIntro: "The Features You're Sure to Love",
    quickStart: "Quick Start",
    quickStartDesc: "Workout with others in real time.",
    structured: "Structured Workout",
    structuredDesc: "Train on an existing structured workout.",
    customWorkout: "Custom Workout",
    customWorkoutDesc: "Customize your own to fit your goals!",
    sceneWorkout: "Scenic Videos",
    sceneWorkoutDesc: "Jump into workouts to explore new places from beautiful forest to breathtaking mountains.",
    planWorkout: "Workout Plans",
    planWorkoutDesc: "Trainer-designer work programs designed to help you achieve your short-to mid-term fitness goals.",
    healthWorkout: "Workouts Tracker",
    healthWorkoutDesc: "Qucik overview of your daily goals. Synchronize and import your activity data via health App.",
    customeApp: "Custom APP",
    customeAppTitle: "Brand Own App Customization Services",
    customeAppDesc: "OEM APP or Brand Embedded FitShow App",
    learnMore: "Learn More",
    embeddedApp: "Brand Embedded APP",
    embeddedAppDesc: "Embed your brand into the FitShow App and we'll provide a back-end operations system where you can publish your own banner ads, coaching videos, blogs, shop products and more!",
    aboutFsApp: "About FitShow App",
    oemAppDesc: "Custom brand own app based on all the features and framework of the FitShow App, Use the FitShow Bluetooth SDK and UI component to quickly develop a customized smart fitness app.",
    deleteAppAccounut:"Are you sure you want to delete your account?",
    ifDelete: "If you delete your account:",
    ifDeleteTipsOne: "You'll lose all data in Fitshow app using the account. after you submit your request, we will disable your account.It may take up to 30 days to fully delete and remove all of your data.",
    ifDeleteTipsTwo: "Once you delete your account, you'll be logged out of this app.",
    ifDeleteTipsThree: "If you change your mind, you can always come back and open a new account with us.",
    inputEmailTip: "Please enter your email address",
    inputPassTip: "Please enter password",
    submit:"Submit",
    errorEmail: "Email format error",
    errorPasss: "Password format error",
    subSuccess: "Submitted Successfully",
    authCert: "Authentication Certificate",
    productCert: "Product Specification",
    commProto: "Communication Protocol",
    technicalData: "Technical Data"
}