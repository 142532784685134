import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo'
import Home from '../pages/Home'
import About from '../pages/About'
import ArticlePage from '../views/home-pages/Article'
import FitshowApp from '../pages/FitshowApp'
import EmbeddedApp from '../pages/EmbeddedApp'
import OemApp from '../pages/OemApp'
import LogoutApp from '../pages/LogoutApp'
import ButtonPage from '../views/ButtonPage'
import ServicePage from '../views/ServicePage'
import ServiceDetailsPage from '../views/ServiceDetailsPage'
import CounterUpPage from '../views/CounterUpPage'
import ProgressbarPage from '../views/ProgressbarPage'
import AccordionPage from '../views/AccordionPage'
import SocialSharePage from '../views/SocialSharePage'
import BlogGridPage from '../views/BlogGridPage'
import BlogGridSidebarPage from '../views/BlogGridSidebarPage'
import BlogListPage from '../views/BlogListPage'
import BlogListSidebarPage from '../views/BlogListSidebarPage'
import BlogDetailsPage from '../views/BlogDetailsPage'
import BlogCategoryPosts from '../components/blog/BlogCategoryPosts'
import TeamPage from '../views/TeamPage'
import PortfolioPage from '../views/PortfolioPage'
import PortfolioThreeColumnPage from '../views/PortfolioThreeColumnPage'
import PortfolioFullWidthPage from '../views/PortfolioFullWidthPage'
import PortfolioGridLayoutPage from '../views/PortfolioGridLayoutPage'
import PortfolioBoxLayoutPage from '../views/PortfolioBoxLayoutPage'
import PortfolioDetailsPage from '../views/PortfolioDetailsPage'
import TestimonialPage from '../views/TestimonialPage'
import TimelinePage from '../views/TimelinePage'
import TabPage from '../views/TabPage'
import PricingPage from '../views/PricingPage'
import SplitSectionPage from '../views/SplitSectionPage'
import CallToActionPage from '../views/CallToActionPage'
import VideoPage from '../views/VideoPage'
import GalleryPage from '../views/GalleryPage'
import ContactPage from '../pages/Contact'
import BrandPage from '../views/BrandPage'
import ErrorPage from '../views/404'
import AdvanceTabPage from '../views/AdvanceTabPage'
import BrandCarouselPage from '../views/BrandCarouselPage'
import AdvancePricingPage from '../views/AdvancePricingPage'
import BusinessConsulting from '../views/home-pages/BusinessConsulting'
import BusinessConsulting2 from '../views/home-pages/BusinessConsulting2'
import Corporate from '../views/home-pages/Corporate'
import Business from '../views/home-pages/Business'
import DigitalAgency from '../views/home-pages/DigitalAgency'
import Finance from '../views/home-pages/Finance'
import Company from '../views/home-pages/Company'
import MarketAgency from '../views/home-pages/MarketAgency'
import TravelAgency from '../views/home-pages/TravelAgency'
import Consulting from '../views/home-pages/Consulting'
import SeoAgency from '../views/home-pages/SeoAgency'
import PersonalPortfolio from '../views/home-pages/PersonalPortfolio'
import EventConference from '../views/home-pages/EventConference'
import CreativePortfolio from '../views/home-pages/CreativePortfolio'
import Freelancer from '../views/home-pages/Freelancer'
import InternationalConsulting from '../views/home-pages/InternationalConsulting'
import Startup from '../views/home-pages/Startup'
import WebAgency from '../views/home-pages/WebAgency'
import AppDownload from '../views/Download_h5'
import QrOta from '../views/Qr_Ota.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'meta.fitshow',
        },
    },
    {
        path: '/article',
        name: 'Article',
        component: ArticlePage,
        meta: {
            title: 'meta.article',
        },
    },
    {
        path: '/qr',
        name: 'DownLoad App',
        component: AppDownload,
        meta: {
            title: 'meta.appDownload',   
        },
    },
    {
        path: '/qr2',
        name: 'DownLoad App',
        component: AppDownload,
        meta: {
            title: 'meta.appDownload',
        },
    },
    {
        path: '/qrOta',
        name: 'Qr Ota',
        component: QrOta,
        meta: {
            title: 'meta.qrOta',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: 'meta.contact',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: 'meta.about',
        },
    },
    {
        path: '/fitshowApp',
        name: 'FitshowApp',
        component: FitshowApp,
        meta: {
            title: 'meta.fitshowApp',
        },
    },
    {
        path: '/embeddedApp',
        name: 'EmbeddedApp',
        component: EmbeddedApp,
        meta: {
            title: 'meta.embeddedApp',
        },
    },
    {
        path: '/oemApp',
        name: 'OemApp',
        component: OemApp,
        meta: {
            title: 'meta.oemApp',
        },
    },
    {
        path: '/logoutApp',
        name: 'LogoutApp',
        component: LogoutApp,
        meta: {
            title: 'meta.logoutApp',
        },
    },
    // {
    //     path: '/service',
    //     name: 'Service',
    //     component: ServicePage,
    //     meta: {
    //         title: 'Service',
    //     },
    // },
    // {
    //     path: '/service-details',
    //     name: 'Service Details',
    //     component: ServiceDetailsPage,
    //     meta: {
    //         title: 'Service Details',
    //     },
    // },
    // {
    //     path: '/counter',
    //     name: 'Counter',
    //     component: CounterUpPage,
    //     meta: {
    //         title: 'Counter',
    //     },
    // },
    // {
    //     path: '/progressbar',
    //     name: 'Progressbar',
    //     component: ProgressbarPage,
    //     meta: {
    //         title: 'Progressbar',
    //     },
    // },
    // {
    //     path: '/accordion',
    //     name: 'Accordion',
    //     component: AccordionPage,
    //     meta: {
    //         title: 'Accordion',
    //     },
    // },
    // {
    //     path: '/social-share',
    //     name: 'Social share',
    //     component: SocialSharePage,
    //     meta: {
    //         title: 'Social Share',
    //     },
    // },
    // {
    //     path: '/blog-grid',
    //     name: 'Blog grid',
    //     component: BlogGridPage,
    //     meta: {
    //         title: 'Blog Grid',
    //     },
    // },
    // {
    //     path: '/blog-grid-sidebar',
    //     name: 'Blog grid sidebar',
    //     component: BlogGridSidebarPage,
    //     meta: {
    //         title: 'Blog Grid Sidebar',
    //     },
    // },
    // {
    //     path: '/blog-list',
    //     name: 'Blog list',
    //     component: BlogListPage,
    //     meta: {
    //         title: 'Blog List',
    //     },
    // },
    // {
    //     path: '/blog-list-sidebar',
    //     name: 'Blog list sidebar',
    //     component: BlogListSidebarPage,
    //     meta: {
    //         title: 'Blog List Sidebar',
    //     },
    // },
    // {
    //     path: '/blog-details/:id',
    //     name: 'Blog details',
    //     component: BlogDetailsPage,
    //     meta: {
    //         title: 'Blog Details',
    //     },
    // },
    // {
    //     path: '/category/:slug',
    //     name: 'Blog Category List',
    //     component: BlogCategoryPosts,
    //     meta: {
    //         title: 'Blog Category List',
    //     },
    // },
    // {
    //     path: '/team',
    //     name: 'Team',
    //     component: TeamPage,
    //     meta: {
    //         title: 'Team',
    //     },
    // },
    // {
    //     path: '/portfolio',
    //     name: 'Portfolio',
    //     component: PortfolioPage,
    //     meta: {
    //         title: 'Portfolio',
    //     },
    // },
    // {
    //     path: '/portfolio-three-column',
    //     name: 'Portfolio three column',
    //     component: PortfolioThreeColumnPage,
    //     meta: {
    //         title: 'Portfolio Three Column',
    //     },
    // },
    // {
    //     path: '/portfolio-full-width',
    //     name: 'Portfolio full width',
    //     component: PortfolioFullWidthPage,
    //     meta: {
    //         title: 'Portfolio Full Width',
    //     },
    // },
    // {
    //     path: '/portfolio-grid-layout',
    //     name: 'Portfolio grid layout',
    //     component: PortfolioGridLayoutPage,
    //     meta: {
    //         title: 'Portfolio Grid Layout',
    //     },
    // },
    // {
    //     path: '/portfolio-box-layout',
    //     name: 'Portfolio box layout',
    //     component: PortfolioBoxLayoutPage,
    //     meta: {
    //         title: 'Portfolio Box Layout',
    //     },
    // },
    // {
    //     path: '/portfolio-details/:id',
    //     name: 'Portfolio details',
    //     component: PortfolioDetailsPage,
    //     meta: {
    //         title: 'Portfolio Details',
    //     },
    // },
    // {
    //     path: '/testimonial',
    //     name: 'Testimonial',
    //     component: TestimonialPage,
    //     meta: {
    //         title: 'Testimonial',
    //     },
    // },
    // {
    //     path: '/timeline',
    //     name: 'Timeline',
    //     component: TimelinePage,
    //     meta: {
    //         title: 'Timeline',
    //     },
    // },
    // {
    //     path: '/tab',
    //     name: 'Tab',
    //     component: TabPage,
    //     meta: {
    //         title: 'Tab',
    //     },
    // },
    // {
    //     path: '/pricing',
    //     name: 'Pricing',
    //     component: PricingPage,
    //     meta: {
    //         title: 'Pricing',
    //     },
    // },
    // {
    //     path: '/spit-section',
    //     name: 'Split section',
    //     component: SplitSectionPage,
    //     meta: {
    //         title: 'Split Section',
    //     },
    // },
    // {
    //     path: '/call-to-action',
    //     name: 'Call to action',
    //     component: CallToActionPage,
    //     meta: {
    //         title: 'Call to Action',
    //     },
    // },
    // {
    //     path: '/video',
    //     name: 'Video',
    //     component: VideoPage,
    //     meta: {
    //         title: 'Video',
    //     },
    // },
    // {
    //     path: '/gallery',
    //     name: 'Gallery',
    //     component: GalleryPage,
    //     meta: {
    //         title: 'Gallery',
    //     },
    // },
    // {
    //     path: '/contact',
    //     name: 'Contact',
    //     component: ContactPage,
    //     meta: {
    //         title: 'Contact',
    //     },
    // },
    // {
    //     path: '/brand',
    //     name: 'Brand',
    //     component: BrandPage,
    //     meta: {
    //         title: 'Brand',
    //     },
    // },
    // {
    //     path: '/error',
    //     name: 'Error',
    //     component: ErrorPage,
    //     meta: {
    //         title: '404',
    //     },
    // },
    // {
    //     path: '/advance-tab',
    //     name: 'Advance tab',
    //     component: AdvanceTabPage,
    //     meta: {
    //         title: 'Advance Tab',
    //     },
    // },
    // {
    //     path: '/brand-carousel',
    //     name: 'Brand carousel',
    //     component: BrandCarouselPage,
    //     meta: {
    //         title: 'Brand Carousel',
    //     },
    // },
    // {
    //     path: '/advance-pricing',
    //     name: 'Advance pricing',
    //     component: AdvancePricingPage,
    //     meta: {
    //         title: 'Advance Pricing',
    //     },
    // },
    // {
    //     path: '/portfolio-details',
    //     name: 'Portfolio details',
    //     component: PortfolioDetailsPage,
    //     meta: {
    //         title: 'Portfolio Details',
    //     },
    // },
    // {
    //     path: '/blog-details',
    //     name: 'Blog details',
    //     component: BlogDetailsPage,
    //     meta: {
    //         title: 'Blog Details',
    //     },
    // },
    // {
    //     path: '/business-consulting',
    //     name: 'Business Consulting',
    //     component: BusinessConsulting,
    //     meta: {
    //         title: 'Business Consulting',
    //     },
    // },
    // {
    //     path: '/business-consulting-2',
    //     name: 'Business Consulting 2',
    //     component: BusinessConsulting2,
    //     meta: {
    //         title: 'Business Consulting 2',
    //     },
    // },
    // {
    //     path: '/corporate',
    //     name: 'Corporate',
    //     component: Corporate,
    //     meta: {
    //         title: 'Corporate',
    //     },
    // },
    // {
    //     path: '/business',
    //     name: 'Business',
    //     component: Business,
    //     meta: {
    //         title: 'Business',
    //     },
    // },
    // {
    //     path: '/digital-agency',
    //     name: 'Digital Agency',
    //     component: DigitalAgency,
    //     meta: {
    //         title: 'Digital Agency',
    //     },
    // },
    // {
    //     path: '/finance',
    //     name: 'Finance',
    //     component: Finance,
    //     meta: {
    //         title: 'Finance',
    //     },
    // },
    // {
    //     path: '/company',
    //     name: 'Company',
    //     component: Company,
    //     meta: {
    //         title: 'Company',
    //     },
    // },
    // {
    //     path: '/marketing-agency',
    //     name: 'Marketing Agency',
    //     component: MarketAgency,
    //     meta: {
    //         title: 'Marketing Agency',
    //     },
    // },
    // {
    //     path: '/travel-agency',
    //     name: 'Travel Agency',
    //     component: TravelAgency,
    //     meta: {
    //         title: 'Travel Agency',
    //     },
    // },
    // {
    //     path: '/consulting',
    //     name: 'Consulting',
    //     component: Consulting,
    //     meta: {
    //         title: 'Consulting',
    //     },
    // },
    // {
    //     path: '/seo-agency',
    //     name: 'SEO Agency',
    //     component: SeoAgency,
    //     meta: {
    //         title: 'SEO Agency',
    //     },
    // },
    // {
    //     path: '/personal-portfolio',
    //     name: 'Personal Portfolio',
    //     component: PersonalPortfolio,
    //     meta: {
    //         title: 'Personal Portfolio',
    //     },
    // },
    // {
    //     path: '/event-conference',
    //     name: 'Event Conference',
    //     component: EventConference,
    //     meta: {
    //         title: 'Event Conference',
    //     },
    // },
    // {
    //     path: '/creative-portfolio',
    //     name: 'Creative Portfolio',
    //     component: CreativePortfolio,
    //     meta: {
    //         title: 'Creative Portfolio',
    //     },
    // },
    // {
    //     path: '/freelancer',
    //     name: 'Freelancer',
    //     component: Freelancer,
    //     meta: {
    //         title: 'Freelancer',
    //     },
    // },
    // {
    //     path: '/international-consulting',
    //     name: 'International Consulting',
    //     component: InternationalConsulting,
    //     meta: {
    //         title: 'International Consulting',
    //     },
    // },
    // {
    //     path: '/startup',
    //     name: 'Startup',
    //     component: Startup,
    //     meta: {
    //         title: 'Startup',
    //     },
    // },
    // {
    //     path: '/web-agency',
    //     name: 'Web Agency',
    //     component: WebAgency,
    //     meta: {
    //         title: 'Web Agency',
    //     },
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
